// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-property-js": () => import("./../../../src/pages/property.js" /* webpackChunkName: "component---src-pages-property-js" */),
  "component---src-pages-propiedades-js": () => import("./../../../src/pages/propiedades.js" /* webpackChunkName: "component---src-pages-propiedades-js" */),
  "component---src-pages-realstate-js": () => import("./../../../src/pages/realstate.js" /* webpackChunkName: "component---src-pages-realstate-js" */),
  "component---src-pages-relocation-js": () => import("./../../../src/pages/relocation.js" /* webpackChunkName: "component---src-pages-relocation-js" */),
  "component---src-pages-sales-js": () => import("./../../../src/pages/sales.js" /* webpackChunkName: "component---src-pages-sales-js" */)
}

